import { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { Avatar } from '@components/atoms/Avatar'
import { MobileNavigationHeaderMenuGroup } from '@/components/organisms/MobileNavigationHeaderMenuGroup'
import { getUserAvatar, useStores } from '@/utils'
import { OutSideOverlay } from '@components/atoms/OutSideOverlay'
import { IPublicUser, IUser } from '@/types'
import LogoSvg from '@/public/images/common/logo_for_mobile.svg'
import Link from 'next/link'
import { NotSignedInMobileHeaderRight } from '@/components/organisms/NotSignedInMobileHeaderRight'
import styles from './index.module.scss'

export const MobileNavigationHeader: FC = observer(() => {
  const { users, viewer } = useStores()
  const [isOpen, setIsOpen] = useState(false)
  const [currentUser, setCurrentUser] = useState<IUser>(null)
  const [userInstance, setUserInstance] = useState<IPublicUser>(null)

  const toggleDrawer = () => {
    setIsOpen(!isOpen)
  }

  const fetchCurrentUser = async () => {
    setCurrentUser(await users.fetchUser({ username: viewer.viewer?.username }))
  }

  useEffect(() => {
    if (viewer.isInitialized && viewer.isSignedIn) {
      fetchCurrentUser()
    }
  }, [viewer.isInitialized])

  useEffect(() => {
    if (currentUser) {
      setUserInstance(users.createUserInstance(currentUser))
    }
  }, [currentUser])

  useEffect(() => {
    if (userInstance) {
      userInstance.fetch()
    }
  }, [userInstance])

  if (viewer.isSignedIn) {
    return (
      <header className={styles.container}>
        <div className={styles.headerGroup}>
          <div className={styles.avatar} onClick={toggleDrawer} onKeyPress={toggleDrawer} role='button' tabIndex={0}>
            <Avatar src={getUserAvatar(viewer.viewer)} />
          </div>
          <div
            className={classNames(styles.content, {
              [styles.activeContent]: isOpen,
            })}
          >
            <MobileNavigationHeaderMenuGroup toggleDrawer={toggleDrawer} user={userInstance} />
          </div>
          <Link href='/'>
            <a className={styles.icon}>
              <LogoSvg />
            </a>
          </Link>
        </div>
        <OutSideOverlay onClick={toggleDrawer} isOpen={isOpen} />
      </header>
    )
  }

  return (
    <header className={styles.notSignedInContainer}>
      <Link href='/'>
        <a className={styles.icon}>
          <LogoSvg />
        </a>
      </Link>
      <NotSignedInMobileHeaderRight />
    </header>
  )
})

import { Field } from 'formik'
import { FC } from 'react'
import { observer } from 'mobx-react'
import { useStores } from '../../../utils'
import styles from './index.module.scss'

export const QuestionCategorySelectField: FC = observer(() => {
  const { questions } = useStores()

  return (
    <div className={styles.container}>
      <Field as='select' name='questionCategoryId'>
        {questions.questionCategories.map((option) => {
          return (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          )
        })}
      </Field>
    </div>
  )
})

import classNames from 'classnames'
import { FC } from 'react'
import styles from './index.module.scss'

type Props = {
  name: string
  isCard?: boolean
}

export const JobTitleTag: FC<Props> = ({ name = '', isCard = false }) => {
  return (
    <div
      className={classNames(styles.container, {
        [styles.card]: isCard,
      })}
    >
      <img src='/images/common/job_title_icon.svg' alt='Job title icon' className={styles.image} />
      {name}
    </div>
  )
}

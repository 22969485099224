import { FC, useEffect } from 'react'
import { observer } from 'mobx-react'
import { IViewerBase } from '@/types'
import { getUserAvatar, useStores } from '@/utils'

type Props = {
  setFieldValue: (base64: string) => void
  questioner: IViewerBase
  title: string
  categoryId: string
}

export const GenerateOgpGroup: FC<Props> = observer(({ setFieldValue, questioner, title, categoryId }) => {
  const { questions } = useStores()
  const category = categoryId
    ? `#${questions?.questionCategories?.find((c) => c.id === categoryId)?.name}`
    : `#${questions?.questionCategories.slice()[0]?.name}`

  useEffect(() => {
    setOgImage()
  }, [title, categoryId])

  interface SeparatedText {
    line: string
    remaining: string
  }

  const createTextLine = (canvas: HTMLCanvasElement, originalText: string): SeparatedText => {
    const context = canvas.getContext('2d')
    const MAX_WIDTH = 1000
    const MAX_LENGTH = 73

    let text = ''
    if (originalText) {
      const textPrefix = originalText.substring(0, MAX_LENGTH)
      const textSuffix = originalText.length > MAX_LENGTH ? '...' : ''
      text = `${textPrefix}${textSuffix}`
    }

    for (let i = 0; i < text.length; i += 1) {
      const line = text.substring(0, i + 1)

      if (context.measureText(line).width > MAX_WIDTH) {
        return {
          line,
          remaining: text.substring(i + 1),
        }
      }
    }

    return {
      line: text,
      remaining: '',
    }
  }

  const createTextLines = (canvas: HTMLCanvasElement, text: string): string[] => {
    const lines: string[] = []
    let currentText = text

    while (currentText !== '') {
      const separatedText = createTextLine(canvas, currentText)
      lines.push(separatedText.line)
      currentText = separatedText.remaining
    }
    return lines
  }

  const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
      const image = new Image()
      image.onload = () => {
        resolve(image)
      }

      image.onerror = reject

      image.src = url
    })

  const toBase64Url = (url: string): Promise<string> =>
    new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.onload = () => {
        const reader = new FileReader()
        reader.onload = () => {
          resolve(reader.result as string)
        }

        reader.onerror = reject

        reader.readAsDataURL(xhr.response)
      }
      xhr.open('GET', url)
      xhr.responseType = 'blob'
      xhr.send()
    })

  const setOgImage = async (): Promise<void> => {
    const WIDTH = 1200
    const HEIGHT = 630
    const DX = 0
    const DY = 0
    const BACKGROUND_IMAGE_PATH = '/images/common/question_ogp.png'

    const canvas = document.createElement('canvas')
    canvas.width = WIDTH
    canvas.height = HEIGHT
    const ctx = canvas.getContext('2d')
    const backgroundImage = await createImage(BACKGROUND_IMAGE_PATH)
    ctx.drawImage(backgroundImage, DX, DY, WIDTH, HEIGHT)

    // 質問タイトル描画
    ctx.font = 'bold 64px Noto Sans JP'
    ctx.fillStyle = '#021639'
    ctx.textAlign = 'center'
    ctx.textBaseline = 'middle'
    const lines = createTextLines(canvas, title)
    lines.forEach((line, index) => {
      const y = 314 + 80 * (index - (lines.length - 1) / 2)
      ctx.fillText(line, 600, y)
    })

    // 質問者名前描画
    ctx.textAlign = 'left'
    ctx.font = 'bold 24px Noto Sans JP'
    ctx.fillText(questioner.name, 168, 120)

    // 質問カテゴリーボックス描画
    ctx.fillStyle = '#021639'
    const metrics = ctx.measureText(category)
    createRoundRectPath(ctx, 80, 494, 32 + metrics.width + 48, 61, 30.5)
    ctx.fill()
    // 質問カテゴリーテキスト描画
    ctx.fillStyle = '#fff'
    ctx.font = '28px Noto Sans JP'
    ctx.fillText(category, 112, 524)

    // 質問者画像描画
    const avatar = await createImage(await toBase64Url(getUserAvatar(questioner)))
    ctx.beginPath()
    ctx.arc(120, 120, 40, 0, Math.PI * 2, false)
    ctx.clip()
    ctx.closePath()
    ctx.drawImage(avatar, 0, 0, avatar.width, avatar.height, 80, 80, 80, 80)

    const ogImage = canvas.toDataURL('image/png')
    setFieldValue(ogImage)
  }

  // 角が四角形のパスを作成
  // x：左上隅のX座標
  // y：左上隅のY座標
  // w：幅
  // h：高さ
  // r：半径
  const createRoundRectPath = (
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    w: number,
    h: number,
    r: number
  ): void => {
    ctx.beginPath()
    ctx.moveTo(x + r, y)
    ctx.lineTo(x + w - r, y)
    ctx.arc(x + w - r, y + r, r, Math.PI * (3 / 2), 0, false)
    ctx.lineTo(x + w, y + h - r)
    ctx.arc(x + w - r, y + h - r, r, 0, Math.PI * (1 / 2), false)
    ctx.lineTo(x + r, y + h)
    ctx.arc(x + r, y + h - r, r, Math.PI * (1 / 2), Math.PI, false)
    ctx.lineTo(x, y + r)
    ctx.arc(x + r, y + r, r, Math.PI, Math.PI * (3 / 2), false)
    ctx.closePath()
  }

  return <></>
})

import { FC, useEffect, useState } from 'react'
import { Field, Form, Formik, FormikErrors, FormikTouched } from 'formik'
import { observer } from 'mobx-react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import * as Yup from 'yup'
import { FormItem } from '@components/atoms/FormItem'
import {
  MessageType,
  QuestionStatusBase,
  IUserQuestion,
  IChoiceInputBase,
  QuestionTypeBase,
  IQuestionBase,
} from '@/types'
import { InputFieldGroup } from '@components/molecules/InputFieldGroup'
import { getUTCPollDeadline, useStores } from '@/utils'
import { SettingButton } from '@components/atoms/SettingButton'
import { QuestionCategorySelectField } from '@/components/atoms/QuestionCategorySelectField'
import { ValidationErrorMessage } from '@/components/atoms/ValidationErrorMessage'
import { TextAreaGroup } from '@/components/molecules/TextAreaGroup'
import { GenerateOgpGroup } from '@/components/molecules/GenerateOgpGroup'
import { ChoicesField } from '@/components/molecules/ChoicesField'
import { MinutesField } from '@/components/atoms/MinutesField'
import { HoursField } from '@/components/atoms/HoursField'
import { DaysField } from '@/components/atoms/DaysField'
import styles from './index.module.scss'

type Props = {
  targetQuestion?: IQuestionBase
  setTargetQuestion?: (targetQ: IQuestionBase) => void
  newQuestion: IUserQuestion
  setNewQuestion: (newQ: IUserQuestion) => void
}

export type Value = {
  title: string
  body: string
  questionCategoryId: string
  status: QuestionStatusBase
  ogImage: string
  questionType: QuestionTypeBase
  choices: IChoiceInputBase[]
  pollDeadline: string
  days: number
  hours: number
  minutes: number
}

export const QuestionFormGroup: FC<Props> = observer(
  ({ targetQuestion, setTargetQuestion, newQuestion, setNewQuestion }) => {
    const { t } = useTranslation(['m', 'validation'])
    const { ui, viewer, messages, questions } = useStores()
    const router = useRouter()
    const question = targetQuestion || newQuestion
    const [isPoll, setIsPoll] = useState(false)

    const fetchQuestionCategories = async () => {
      if (viewer.isInitialized) {
        await questions.fetchQuestionCategories()
      }
    }

    const toggleIsPoll = () => {
      if (!viewer.isInitialized) {
        return
      }

      setIsPoll(!isPoll)
    }

    useEffect(() => {
      if (questions?.questionCategories.length === 0) {
        fetchQuestionCategories()
      }
    }, [viewer.isInitialized])

    const initialValue: Value = {
      title: question?.title ? question?.title : '',
      body: question?.body ? question?.body : '',
      status: question?.status || QuestionStatusBase.PUBLISHED,
      questionCategoryId: question?.questionCategory?.id,
      ogImage: '',
      questionType: QuestionTypeBase.QUESTION,
      choices: [{ name: '' }, { name: '' }], // デフォルトで選択肢を 2 個用意する
      pollDeadline: '',
      days: 1,
      hours: 0,
      minutes: 0,
    }

    const validationSchema = Yup.object().shape({
      title: Yup.string().required(t('validation:必須項目です')),
      questionType: Yup.string().oneOf(Object.values(QuestionTypeBase)),
      choices: Yup.array().when('questionType', {
        is: QuestionTypeBase.POLL,
        then: Yup.array()
          .of(
            Yup.object().shape({
              name: Yup.string()
                .max(30, 'This field must be 30 characters or less')
                .required(t('validation:必須項目です')),
            })
          )
          .min(2, 'Minimum of 2 choices')
          .max(4, 'Maximum of 4 choices'),
        otherwise: Yup.array(),
      }),
    })

    const isNotValidated = (fe: FormikErrors<Value>, ft: FormikTouched<Value>) => {
      if (isPoll && typeof fe.choices === 'string' && typeof ft.choices === 'string') {
        return true
      }
      if (fe.title && ft.title) {
        return true
      }
      return false
    }

    const onClick = () => {
      if (targetQuestion) {
        ui.toggleForumModal()
      }
    }

    return (
      <Formik
        initialValues={initialValue}
        validationSchema={validationSchema}
        onSubmit={async (values: Value, actions) => {
          if (targetQuestion) {
            const output = await viewer.viewer?.updateMyQuestion(targetQuestion.id, {
              title: values.title,
              body: values.body,
              questionCategoryId: values.questionCategoryId,
              status: values.status,
              ogImage: values.ogImage,
            })
            actions.setSubmitting(false)
            if (output.isSuccessful) {
              actions.resetForm()
              setTargetQuestion(output.data.question)
            } else {
              messages.add({
                type: MessageType.Error,
                body: 'Failed to update your post.',
                isDismissable: true,
                ttl: 5000,
              })
            }
          } else {
            const output = await viewer.viewer?.addMyQuestion(
              {
                title: values.title,
                body: values.body,
                questionCategoryId: values.questionCategoryId,
                status: values.status,
                ogImage: values.ogImage,
                questionType: values.questionType,
                pollDeadline: isPoll ? getUTCPollDeadline(values.days, values.hours, values.minutes) : '',
              },
              isPoll ? values.choices : []
            )
            actions.setSubmitting(false)
            if (output.isSuccessful) {
              actions.resetForm()
              setNewQuestion(output.data.question)
              router.push({ pathname: router.asPath, query: { act: 'post' } })
            } else {
              messages.add({
                type: MessageType.Error,
                body: 'Failed to add your post.',
                isDismissable: true,
                ttl: 5000,
              })
            }
          }
        }}
      >
        {({ isSubmitting, setFieldValue, errors, touched, values }) => {
          useEffect(() => {
            if (!question) {
              return
            }

            setFieldValue('title', question?.title)
            setFieldValue('body', question?.body)
            setFieldValue('questionCategoryId', question?.questionCategory?.id)
            setFieldValue('status', question?.status)
            setFieldValue('ogImage', question?.ogImage)
            setFieldValue('questionType', question?.questionType)
          }, [question])

          return (
            <Form>
              <FormItem label='Title' isRequired isColumn color='black'>
                <Field name='title' placeholder='Add title' fieldType='setting' component={InputFieldGroup} />
              </FormItem>
              <FormItem label='Categories' isRequired isColumn color='black'>
                <QuestionCategorySelectField />
              </FormItem>
              {!isPoll && (
                <FormItem label='Contents of consultation' isColumn color='black'>
                  <Field name='body' placeholder='' fieldType='setting' component={TextAreaGroup} />
                </FormItem>
              )}
              <GenerateOgpGroup
                setFieldValue={(base64) => {
                  setFieldValue('ogImage', base64)
                }}
                questioner={viewer?.viewer}
                title={values.title}
                categoryId={values.questionCategoryId}
              />
              {isPoll && (
                <div className={styles.pollGroup}>
                  <FormItem label='Poll' isColumn color='black' hasNotLabelMarginBottom>
                    <ChoicesField values={values} errors={errors} touched={touched} />
                  </FormItem>
                  <FormItem label='Poll length' isColumn color='black'>
                    <div className={styles.pollDeadline}>
                      <DaysField />
                      <HoursField />
                      <MinutesField />
                    </div>
                  </FormItem>
                </div>
              )}
              <div className={styles.buttonContainer}>
                {!targetQuestion && (
                  <button
                    type='button'
                    className={isPoll ? styles.removePollBtn : styles.createPollBtn}
                    onClick={() => {
                      toggleIsPoll()
                      setFieldValue('questionType', isPoll ? QuestionTypeBase.QUESTION : QuestionTypeBase.POLL)
                    }}
                    // onClick={toggleIsPoll}
                  >
                    {isPoll ? 'Remove poll' : 'Create poll'}
                  </button>
                )}
                <SettingButton
                  isLoading={isSubmitting}
                  disabled={isNotValidated(errors, touched)}
                  loadingColor='black'
                  buttonType='submit'
                  buttonSize='medium'
                  color='blue'
                  onClick={onClick}
                >
                  {targetQuestion ? 'Save' : 'Post'}
                </SettingButton>
              </div>
              {isNotValidated(errors, touched) && (
                <div className={styles.validation}>
                  <ValidationErrorMessage>Fill out the required fields.</ValidationErrorMessage>
                </div>
              )}
            </Form>
          )
        }}
      </Formik>
    )
  }
)

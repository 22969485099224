import { FC } from 'react'
import { observer } from 'mobx-react'
import { useStores } from '@/utils'
import { MessageType } from '@/types'
import LogoutSvg from '@/public/images/common/logout.svg'
import styles from './index.module.scss'

type Props = {
  isMobile?: boolean
}

export const SignOutGroup: FC<Props> = observer(({ isMobile }) => {
  const { viewer, messages } = useStores()

  const signOut = async () => {
    const isSuccessful = await viewer.signOut()
    if (isSuccessful) {
      messages.add({
        type: MessageType.Info,
        body: 'Logged out.',
        isTranslated: true,
        ttl: 5000,
      })
      // ログアウト後、確実にメモリをクリアするためにリロードさせる
      window.location.href = '/signin'
    }
  }

  return (
    <button type='button' className={isMobile ? styles.containerForMobile : styles.container} onClick={signOut}>
      <div className={styles.icon}>
        <LogoutSvg />
      </div>
      <p className={isMobile ? styles.textForMobile : styles.text}>Log out</p>
    </button>
  )
})

import { FC } from 'react'
import { ValidationErrorMessage } from '@/components/atoms/ValidationErrorMessage'
import { FieldArray, Field, getIn, FormikErrors, FormikTouched } from 'formik'
import { Value } from '@/components/organisms/QuestionFormGroup'
import { InputFieldGroup } from '../InputFieldGroup'
import styles from './index.module.scss'

type Props = {
  values: Value
  errors: FormikErrors<Value>
  touched: FormikTouched<Value>
}

export const ChoicesField: FC<Props> = ({ values, errors, touched }) => {
  return (
    <>
      <FieldArray
        name='choices'
        render={(arrayHelpers) => (
          <div className={styles.container}>
            <div className={styles.content}>
              {values.choices.map((choice, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index} className={styles.choice}>
                  <span className={styles.label}>{`Choice${index + 1}`}</span>
                  <Field
                    name={`choices.${index}.name`}
                    placeholder={index === 2 || index === 3 ? `Choice${index + 1}(optional)` : `Choice${index + 1}`}
                    fieldType='setting'
                    component={InputFieldGroup}
                  />
                  {getIn(errors, `choices[${index}].name`) && getIn(touched, `choices[${index}].name`) && (
                    <div className={styles.message}>
                      <ValidationErrorMessage>{getIn(errors, `choices[${index}].name`)}</ValidationErrorMessage>
                    </div>
                  )}
                  {(index === 2 || index === 3) && (
                    <button className={styles.deleteBtn} type='button' onClick={() => arrayHelpers.remove(index)}>
                      <img src='/images/common/delete_file_icon.svg' alt='Delete Choice' />
                    </button>
                  )}
                </div>
              ))}
            </div>
            {values.choices.length < 4 && (
              <div className={styles.btnContent}>
                <button className={styles.addBtn} type='button' onClick={() => arrayHelpers.push({ name: '' })}>
                  <img src='/images/common/add_icon.svg' alt='Add Choice' />
                </button>
              </div>
            )}
          </div>
        )}
      />
      {typeof errors.choices === 'string' && (
        <div className={styles.message}>
          <ValidationErrorMessage>{errors.choices}</ValidationErrorMessage>
        </div>
      )}
    </>
  )
}

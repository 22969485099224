import { FC, useState } from 'react'
import styles from './index.module.scss'
import { OutSideOverlay } from '../../atoms/OutSideOverlay'
import { HamburgerIcon } from '../../molecules/HamburgerIcon'
import { NotSignedInMobileHeaderRightDropdown } from '../NotSignedInMobileHeaderRightDropdown'

export const NotSignedInMobileHeaderRight: FC = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleDropDown = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className={styles.container}>
      <HamburgerIcon isOpen={isOpen} onClick={toggleDropDown} size='small' color='gray' />
      {isOpen && <NotSignedInMobileHeaderRightDropdown toggleDropDown={toggleDropDown} />}
      <OutSideOverlay onClick={toggleDropDown} isOpen={isOpen} />
    </div>
  )
}

import { FC } from 'react'
import { observer } from 'mobx-react'
import { IUserQuestion } from '@/types'
import Link from 'next/link'
import { CloseButton } from '@/components/atoms/CloseButton'
import styles from './index.module.scss'

type Props = {
  question: IUserQuestion
  onClick: () => void
}

export const ForumModalPostedContent: FC<Props> = observer(({ question, onClick }) => {
  return (
    <>
      <div className={styles.heading}>
        <div className={styles.closeButton}>
          <CloseButton onClick={onClick} isLarge />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.title}>Your Question has been posted!</div>
        <p className={styles.body}>
          You can give AQA tokens to those who answer your question as a token of our appreciation.
        </p>
        {question && (
          <>
            <div className={styles.shareContainer}>
              <a
                href={`http://twitter.com/share?url=${`${process.env.NEXT_PUBLIC_APP_BASE_URL}/forum/${question?.slug}`}&text=${
                  question?.title
                }`}
                target='_blank'
                rel='nofollow noopener noreferrer'
              >
                <div className={styles.shareContent}>
                  <img src='/images/common/twitter_icon.png' alt='Twitter' />
                  <p className={styles.shareText}>Share tweet</p>
                </div>
              </a>
            </div>
            <Link href={`/forum/${question.slug}`}>
              <div role='button' tabIndex={0} onClick={onClick} onKeyPress={onClick} className={styles.link}>
                <a>Go to see your post</a>
              </div>
            </Link>
          </>
        )}
      </div>
    </>
  )
})

import { FC, FocusEvent, FormEvent } from 'react'
import { FieldProps } from 'formik'
import classNames from 'classnames'
import styles from './index.module.scss'
import { ValidationErrorMessage } from '../../atoms/ValidationErrorMessage'

type Props = {
  placeholder?: string
  type?: 'text' | 'email' | 'password' | 'number' | 'date' | 'month'
  fieldType: 'default' | 'setting' | 'search' | 'searchLarge' | 'verticalForm' | 'searchRound'
  testid?: string
  customBlurHandler?: (e: FocusEvent<HTMLInputElement>) => void
  customChangeHandler?: (e: FormEvent<HTMLInputElement>) => void
}

export const InputFieldGroup: FC<Props & FieldProps<never>> = ({
  field,
  form: { touched, errors },
  placeholder = '',
  type = 'text',
  fieldType = 'default',
  testid,
  customBlurHandler,
  customChangeHandler,
}) => {
  const onBlur = (e: FocusEvent<HTMLInputElement>) => {
    field.onBlur(e)

    if (customBlurHandler) {
      customBlurHandler(e)
    }
  }

  const className = classNames(styles.input, {
    [styles.setting]: fieldType === 'setting',
    [styles.search]: fieldType === 'search',
    [styles.searchLarge]: fieldType === 'searchLarge',
    [styles.verticalForm]: fieldType === 'verticalForm',
    [styles.searchRound]: fieldType === 'searchRound',
  })
  return (
    <div className={styles.container}>
      <div className={styles.inputContainer}>
        {/* Atoms に切り出すと Props のバケツリレーが面倒になるので、ここでスタイル当て */}
        <input
          className={className}
          type={type}
          placeholder={placeholder}
          name={field.name}
          data-testid={testid}
          value={field.value}
          onChange={customChangeHandler || field.onChange}
          onBlur={onBlur}
        />
      </div>
      {touched[field.name] && errors[field.name] && (
        <div className={styles.message}>
          <ValidationErrorMessage>{errors[field.name]}</ValidationErrorMessage>
        </div>
      )}
    </div>
  )
}

import { FC } from 'react'
import { observer } from 'mobx-react'
import styles from './index.module.scss'
import { NotSignedInMobileHeaderRightDropdownMenuList } from '../NotSignedInMobileHeaderRightDropdownMenuList'

type Props = {
  toggleDropDown: () => void
}

export const NotSignedInMobileHeaderRightDropdown: FC<Props> = observer(({ toggleDropDown }) => {
  return (
    <div className={styles.container} role='button' tabIndex={0} onClick={toggleDropDown} onKeyPress={toggleDropDown}>
      <div className={styles.content}>
        <NotSignedInMobileHeaderRightDropdownMenuList />
      </div>
    </div>
  )
})

import { observer } from 'mobx-react'
import { useRouter } from 'next/router'
import { FC } from 'react'
import Link from 'next/link'
import { getSignInPath } from '@/utils'
import styles from './index.module.scss'

export const SignInAndJoinBtnGroup: FC = observer(() => {
  const router = useRouter()

  return (
    <div className={styles.contaienr}>
      <button
        type='button'
        className={styles.signInLink}
        onClick={() => {
          const path = getSignInPath(router.asPath)
          router.push(path)
        }}
      >
        <div className={styles.signInText}>Log in</div>
      </button>
      <Link href='/signup'>
        <a className={styles.joinLink}>
          <span className={styles.joinText}>Join</span>
        </a>
      </Link>
    </div>
  )
})

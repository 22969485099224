import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { FC } from 'react'
import styles from './index.module.scss'

type Props = {
  followingCount?: number
  followersCount: number
  linkTofollowing?: string
  linkTofollowers: string
}

// TODO: 1000 以上の場合 K に変更する
export const FollowingFollowersItem: FC<Props> = ({
  followingCount,
  followersCount,
  linkTofollowing,
  linkTofollowers,
}) => {
  const { t } = useTranslation(['p'])
  return (
    <div className={styles.container}>
      {followingCount != null && (
        <Link href={linkTofollowing}>
          <a className={styles.number}>
            {followingCount} <span className={styles.unit}>{t('フォロー中')}</span>
          </a>
        </Link>
      )}
      <Link href={linkTofollowers}>
        <a className={styles.number}>
          {followersCount} <span className={styles.unit}>{t('フォロワー')}</span>
        </a>
      </Link>
    </div>
  )
}

import { FC, FocusEvent } from 'react'
import { FieldProps } from 'formik'
import classNames from 'classnames'
import styles from './index.module.scss'
import { ValidationErrorMessage } from '../../atoms/ValidationErrorMessage'

type Props = {
  placeholder?: string
  fieldType: 'default' | 'setting' | 'search' | 'baseColor'
  testid?: string
  customBlurHandler?: (e: FocusEvent<HTMLTextAreaElement>) => void
  disabled?: boolean
  isSmall?: boolean
}

export const TextAreaGroup: FC<Props & FieldProps<never>> = ({
  field,
  form: { touched, errors },
  placeholder = '',
  fieldType = 'default',
  testid,
  customBlurHandler,
  disabled = false,
  isSmall = false,
}) => {
  const onBlur = (e: FocusEvent<HTMLTextAreaElement>) => {
    field.onBlur(e)

    if (customBlurHandler) {
      customBlurHandler(e)
    }
  }
  const className = classNames(styles.input, {
    [styles.setting]: fieldType === 'setting',
    [styles.disabled]: disabled,
    [styles.isSmall]: isSmall,
    [styles.baseColor]: fieldType === 'baseColor',
  })

  return (
    <div className={styles.container}>
      <div className={styles.inputContainer}>
        {/* Atoms に切り出すと Props のバケツリレーが面倒になるので、ここでスタイル当て */}
        <textarea
          className={className}
          placeholder={placeholder}
          name={field.name}
          value={field.value}
          data-testid={testid}
          onChange={field.onChange}
          onBlur={onBlur}
          disabled={disabled}
        />
      </div>
      {touched[field.name] && errors[field.name] && (
        <div className={styles.message}>
          <ValidationErrorMessage>{errors[field.name]}</ValidationErrorMessage>
        </div>
      )}
    </div>
  )
}

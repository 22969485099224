import { FC } from 'react'
import { IPublicUser } from '@/types'
import VerifiedBadgeSvg from '@/public/images/common/verified_badge.svg'
import { JobTitleTag } from '@/components/atoms/JobTitleTag'
import { FollowingFollowersItem } from '@/components/molecules/FollowingFollowersItem'
import { Avatar } from '@/components/atoms/Avatar'
import { getUserAvatar } from '@/utils'
import styles from './index.module.scss'

type Props = {
  user: IPublicUser
}

export const MobileNavigationHeaderMenuUserInfo: FC<Props> = ({ user }) => {
  return (
    <div className={styles.container}>
      <div className={styles.avatar}>
        <Avatar src={getUserAvatar(user)} />
      </div>
      <div className={styles.name}>
        <div className={styles.text}>{user?.name}</div>
        {user?.profile?.isVerifiedAccount && (
          <span className={styles.badge}>
            <VerifiedBadgeSvg />
          </span>
        )}
      </div>
      {user?.profile?.jobTitle && (
        <div className={styles.tags_list}>
          <JobTitleTag name={user?.profile?.jobTitle} />
        </div>
      )}
      <div className={styles.following_followers}>
        <FollowingFollowersItem
          followingCount={user?.followingCount}
          followersCount={user?.followersCount}
          linkTofollowing={`/users/${user?.username}/following`}
          linkTofollowers={`/users/${user?.username}/followers`}
        />
      </div>
    </div>
  )
}

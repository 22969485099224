import { FC } from 'react'
import { observer } from 'mobx-react'
import { IQuestionBase, IUserQuestion } from '@/types'
import { CloseButton } from '@/components/atoms/CloseButton'
import { QuestionFormGroup } from '@/components/organisms/QuestionFormGroup'
import { useStores } from '@/utils'
import styles from './index.module.scss'

type Props = {
  targetQuestion?: IQuestionBase
  setTargetQuestion?: (question: IQuestionBase) => void
  newQuestion: IUserQuestion
  setNewQuestion: (question: IUserQuestion) => void
  onClick: () => void
}

export const ForumModalAskContent: FC<Props> = observer(
  ({ targetQuestion, setTargetQuestion, newQuestion, setNewQuestion, onClick }) => {
    const { ui } = useStores()
    return (
      <>
        <div className={styles.heading}>
          <div>
            <h2>Ask a Question</h2>
            <p>Why don&apos;t you ask question or concerns that are on your mind?</p>
          </div>
          <div className={styles.closeButton}>
            <CloseButton onClick={onClick} isLarge={!ui.isMobile} />
          </div>
        </div>
        <QuestionFormGroup
          targetQuestion={targetQuestion}
          setNewQuestion={(newQ: IUserQuestion) => setNewQuestion(newQ)}
          newQuestion={newQuestion}
          setTargetQuestion={(targetQ: IQuestionBase) => setTargetQuestion(targetQ)}
        />
      </>
    )
  }
)

import { Field } from 'formik'
import { FC } from 'react'
import styles from './index.module.scss'

export const DaysField: FC = () => {
  return (
    <div className={styles.container}>
      <span className={styles.label}>Days</span>
      <div className={styles.content}>
        <Field as='select' name='days'>
          <option value={0}>0</option>
          <option value={1}>1</option>
          <option value={2}>2</option>
          <option value={3}>3</option>
          <option value={4}>4</option>
          <option value={5}>5</option>
          <option value={6}>6</option>
          <option value={7}>7</option>
        </Field>
      </div>
    </div>
  )
}

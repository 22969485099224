import { FC } from 'react'
import { CloseButton } from '@/components/atoms/CloseButton'
import { IPublicUser } from '@/types'
import { MobileNavigationHeaderMenuUserInfo } from '@/components/molecules/MobileNavigationHeaderMenuUserInfo'
import styles from './index.module.scss'
import { MobileNavigationHeaderMenuList } from '../MobileNavigationHeaderMenuList'

type Props = {
  toggleDrawer: () => void
  user: IPublicUser
}

export const MobileNavigationHeaderMenuGroup: FC<Props> = ({ toggleDrawer, user }) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerText}>Account Info</div>
        <CloseButton onClick={toggleDrawer} />
      </div>
      <MobileNavigationHeaderMenuUserInfo user={user} />
      <MobileNavigationHeaderMenuList user={user} />
    </div>
  )
}

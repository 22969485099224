import { FC } from 'react'
import Link from 'next/link'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { GlobalNotificationGroup } from '@/components/organisms/GlobalNotificationGroup'
import { UserInvitationGroup } from '@/components/molecules/UserInvitationGroup'
import { SignOutGroup } from '@/components/molecules/SignOutGroup'
import { sanitizeUrl } from '@/utils'
import styles from './index.module.scss'

type Props = {
  name: string
  link?: string
  isActive?: boolean
  icon?: React.SVGProps<SVGElement>
  iconActive?: React.SVGProps<SVGElement>
  isExternal?: boolean
}

export const MobileNavigationHeaderMenuItem: FC<Props> = observer(
  ({ name, link, isActive, icon, iconActive, isExternal }) => {
    if (name === 'Notifications') {
      return <GlobalNotificationGroup isMobile isActive={isActive} />
    }
    if (name === 'Invite') {
      return <UserInvitationGroup isMobile />
    }
    if (name === 'Log out') {
      return <SignOutGroup isMobile />
    }

    if (isExternal) {
      return (
        <a key={link} className={styles.container} href={sanitizeUrl(link)} target='_blank' rel='noreferrer'>
          <div className={styles.icon}>{isActive ? iconActive : icon}</div>
          <p
            className={classNames(styles.text, {
              [styles.active]: isActive,
            })}
          >
            {name}
          </p>
        </a>
      )
    }

    return (
      <Link href={link}>
        <a className={styles.container}>
          <div className={styles.icon}>{isActive ? iconActive : icon}</div>
          <p
            className={classNames(styles.text, {
              [styles.active]: isActive,
            })}
          >
            {name}
          </p>
        </a>
      </Link>
    )
  }
)

import { FC, useState } from 'react'
import { observer } from 'mobx-react'
import { useRouter } from 'next/router'
import { IQuestionBase, IUserQuestion } from '@/types'
import { getQueryValue, useStores } from '@/utils'
import { OutSideOverlay } from '@components/atoms/OutSideOverlay'
import { ForumModalPostedContent } from '@components/organisms/ForumModalPostedContent'
import { ForumModalAskContent } from '@components/organisms/ForumModalAskContent'
import styles from './index.module.scss'

type Props = {
  targetQuestion?: IQuestionBase
  setTargetQuestion?: (targetQ: IQuestionBase) => void
}

export const ForumModal: FC<Props> = observer(({ targetQuestion, setTargetQuestion }) => {
  const { ui, viewer } = useStores()
  const router = useRouter()
  const [newQuestion, setNewQuestion] = useState<IUserQuestion>(null)
  const actParam = getQueryValue('act', router)
  let act = 'ask'
  if (actParam === 'post') {
    act = 'post'
  } else {
    act = 'ask'
  }

  const toggleModal = () => {
    if (!viewer.isInitialized) {
      return
    }

    ui.toggleForumModal()
  }

  const finishModal = () => {
    if (!viewer.isInitialized) {
      return
    }

    ui.toggleForumModal()
    // 元の path に戻す
    router.push({ pathname: router.asPath.slice(0, -9) })
  }

  return (
    <div className={styles.container} style={ui.isForumModalOpen ? {} : { display: 'none' }}>
      <OutSideOverlay onClick={toggleModal} isOpen={ui.isForumModalOpen} backGroundColor='gray' />
      <div className={styles.modal}>
        <div className={styles.card}>
          <div className={act !== 'ask' ? styles.hidden : undefined}>
            <ForumModalAskContent
              targetQuestion={targetQuestion?.questioner.username === viewer.viewer?.username ? targetQuestion : null}
              setTargetQuestion={(targetQ: IQuestionBase) => setTargetQuestion(targetQ)}
              newQuestion={newQuestion}
              setNewQuestion={(newQ: IUserQuestion) => setNewQuestion(newQ)}
              onClick={toggleModal}
            />
          </div>
          <div className={act !== 'post' ? styles.hidden : undefined}>
            <ForumModalPostedContent question={newQuestion} onClick={finishModal} />
          </div>
        </div>
      </div>
    </div>
  )
})

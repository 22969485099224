import { FC } from 'react'
import { observer } from 'mobx-react'
import { useStores } from '@/utils'
import { MessageType } from '@/types'
import InvitedSvg from '@/public/images/common/invited.svg'
import styles from './index.module.scss'

type Props = {
  isMobile?: boolean
}

export const UserInvitationGroup: FC<Props> = observer(({ isMobile }) => {
  const { ui, messages, viewer } = useStores()

  const toggleInvitationModal = (): void => {
    ui.toggleUserInvitationModal()
  }

  const onClickConnectWalletMessage = () => {
    messages.add({
      type: MessageType.Info,
      body: 'wallet connection is required for invitations. Invite experts to earn AQA token!',
      isDismissable: true,
      isTranslated: true,
      ttl: 5000,
    })
  }

  return (
    <button
      className={isMobile ? styles.containerForMobile : styles.container}
      type='button'
      onClick={viewer.viewer?.profile?.solanaAddress ? toggleInvitationModal : onClickConnectWalletMessage}
    >
      <div className={styles.icon}>
        <InvitedSvg />
      </div>
      {ui.isUserInvitationModalOpen && (
        <div
          className={styles.outSide}
          role='button'
          tabIndex={0}
          onClick={toggleInvitationModal}
          onKeyPress={() => {}}
        >
          outside
        </div>
      )}
      <p className={isMobile ? styles.textForMobile : styles.text}>Invite</p>
    </button>
  )
})
